
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































$bp: m;

.blog,
[class*='blog--'] {
}

.blog__hero {
  ::v-deep .hero__title__logo {
    display: none;
  }
}

.blog__cards {
  // prettier-ignore
  @include fluid(margin-top,(xxs: 45px, xxl: 90px));
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 45px, xxl: 90px));

  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, auto));
  justify-content: center;
  width: 100%;

  @include mq(xxl) {
    gap: 6rem;
    grid-template-columns: repeat(auto-fit, minmax(30rem, calc(33.33% - 4rem)));
  }
}

.blog__cards__item {
  display: flex;
  flex-direction: column;

  ::v-deep .card__text {
    margin-bottom: 3rem;
  }

  // ::v-deep .card__link {
  // margin-top: auto;
  // }

  ::v-deep .card__title__logo {
    display: none;
  }
}

.blog__cards__more {
  display: block;
  margin-right: auto;
  margin-left: auto;
  color: $c-white;
  background: $c-green;
  cursor: pointer;
}
