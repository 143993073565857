
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































.input {
  position: relative;
  width: 100%;
}

.input__field {
  width: 100%;
  padding: 2rem 2.5rem;
  color: $c-brown;
  background: transparent;
  border: 1px solid $c-brown;

  .has-empty & {
    &.is-empty {
      border: 0.1rem solid $c-red;
    }
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px $c-beige inset;
    -webkit-text-fill-color: $c-brown;
  }

  &:disabled:-webkit-autofill {
    -webkit-text-fill-color: $c-brown;
  }

  &:focus + label,
  &.is-active + label {
    top: 0;
  }
}

.input__label {
  @include center-y;

  left: 2rem;
  display: flex;
  align-items: center;
  height: auto;
  padding: 0.5rem;
  pointer-events: none;
  background: $c-beige;
  transition: top 0.2s;
}

.input__error {
  .is-empty & {
    display: block;
  }
}
