
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































$bp: m;

.card,
[class*='card--'] {
  color: $c-brown;
  text-decoration: none;
}

.card__picture {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 20px, xxl: 40px));

  position: relative;
  overflow: hidden;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: (23 / 29) * 100%;
  }

  img {
    @include image-fit;

    transition: transform 0.25s;
  }

  .card:hover & {
    img {
      transform: scale(1.1);
    }
  }

  @include mq($bp) {
    &::before {
      padding-top: (3 / 4) * 100%;
    }
  }
}

.card__title {
  @extend %fw-normal;

  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 10px, xxl: 20px));

  // prettier-ignore
  @include fluid(font-size,(xxs: 36px, xxl: 96px));
  // prettier-ignore
  @include fluid(line-height,(xxs: 40px, xxl: 96px));

  font-family: $ff-alt;
  font-style: italic;

  .card.blog & {
    // prettier-ignore
    @include fluid(font-size,(xxs: 24px, xxl: 36px));
    // prettier-ignore
    @include fluid(line-height,(xxs: 28px, xxl: 40px));

    font-style: normal;
  }
}

.card__title__logo {
  // prettier-ignore
  @include fluid(width,(xxs: 140px, xxl: 185px));

  display: block;
  height: auto;
  margin-bottom: 1rem;
  fill: $c-beige;
}

.card__text {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 20px, xxl: 40px));
}

.card__link {
  // prettier-ignore
  @include fluid(font-size,(xxs: 14px, xxl: 16px));

  // prettier-ignore
  // @include fluid(letter-spacing,(xxs: 2.2px, xxl: 3.2px));
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;

  &::before {
    content: '';
    width: 0;
    height: 1px;
    background: $c-brown;
    transition: width 0.25s;
  }

  svg {
    width: 0.7rem;
    margin-right: 2.5rem;
    fill: $c-brown;
    transition: transform 0.15s;
  }

  .card:hover & {
    &::before {
      width: 5rem;
    }
  }
}
