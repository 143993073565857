
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































$bp: s;

.duo-text,
[class*='duo-text--'] {
}

.duo-text__title {
  @extend %fw-normal;

  // prettier-ignore
  @include fluid(font-size,(xxs: 36px, xxl: 96px));
  // prettier-ignore
  @include fluid(line-height,(xxs: 40px, xxl: 96px));
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 20px, xxl: 80px));

  font-family: $ff-alt;
}

.duo-text__title__headline {
  display: block;
  font-style: italic;
}

.duo-text__picture {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 20px, xxl: 60px));

  width: 100%;
}

.duo-text__content {
  @include mq($bp) {
    display: flex;
    justify-content: space-between;
  }
}

.duo-text__content__title {
  margin-bottom: 2rem;

  @include mq($bp) {
    width: col(8, 18);
  }
}

.duo-text__content__text {
  @include mq($bp) {
    width: col(8, 18);
  }
}
