
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































































.select {
  position: relative;
  border: 0.1rem solid $c-brown;

  .has-empty & {
    &.is-empty {
      border: 0.1rem solid $c-red;
    }
  }
}

.real-select {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.options {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: calc(100% + 2px);
  height: auto;
  margin: 0 -1px;
  padding: 0;
  background: $c-white;
  border: 1px solid $c-black;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;

  .is-open & {
    opacity: 1;
    visibility: visible;
  }

  li {
    list-style-type: none;
  }
}

.option {
  display: flex;
  width: 100%;
  padding: 0;
  color: $c-brown;
  background: transparent;
  border: 0;
  cursor: pointer;

  &:not(.placeholder) {
    padding: 0 2.5rem;
    font-size: 1.4rem;
    line-height: 50px;

    &:hover {
      background: $c-beige;
    }
  }
}

.placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.5rem;
}

.icon {
  width: 1.2rem;
  height: 0.8rem;
  fill: $c-brown;
  transform: rotate(90deg);
  transition: transform 0.2s;

  .is-open & {
    transform: rotate(90deg) scale(-1);
  }
}
