
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































$bp: m;

.masonry,
[class*='masonry--'] {
  @include mq($bp) {
    display: flex;
    justify-content: space-between;
  }
}

.masonry__col {
  @include mq($bp) {
    width: col(8, 18);
  }
}

.masonry__col__item {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 40px, xxl: 140px));

  display: block;

  ::v-deep .card__picture {
    &::before {
      display: none;
    }

    img {
      position: relative;
    }
  }

  ::v-deep .card__title__logo {
    fill: $c-skin;
  }
}
