
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































































































































































































































































































































.contact,
[class*='contact--'] {
}

.contact__title {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 60px, xxl: 120px));

  color: $c-green;
  text-align: center;

  ::v-deep strong {
    @extend %fw-normal;

    display: block;
    font-style: normal;
  }

  h1 {
    // prettier-ignore
    @include fluid(margin-bottom,(xxs: 20px, xxl: 50px));
  }
}

.contact__title__message {
  margin: 4rem 0;
}

.contact__form {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 80px, xxl: 160px));
}

.contact__form__row {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 20px, xxl: 50px));

  @include mq(s) {
    display: flex;
    gap: 4rem;
  }
}

.contact__form__row__field {
  ::v-deep {
    input[type='date']:required:invalid::-webkit-datetime-edit {
      color: transparent;
    }

    input[type='date']:focus::-webkit-datetime-edit {
      color: $c-brown !important;
    }
  }

  @include mq($until: s) {
    &:not(:last-child) {
      // prettier-ignore
      @include fluid(margin-bottom,(xxs: 20px, xxl: 50px));
    }
  }
}

.contact__form__message {
  margin-bottom: 5rem;
  color: $c-skin;
}

.contact__form__submit {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $c-white;
  background: $c-green;
  border-color: $c-green;
  cursor: pointer;
}

.contact__form__submit__loader {
  position: absolute;
  right: 1rem;
  height: 1.5em;
}

.contact__form__error {
  margin: 2rem 0;
  color: $c-red;
}
