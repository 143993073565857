
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































.textarea {
  position: relative;
  width: 100%;
}

.textarea__field {
  width: 100%;
  min-height: 32rem;
  padding: 2rem 2.5rem;
  color: $c-brown;
  background: transparent;
  border: 1px solid $c-brown;

  .has-empty & {
    &.is-empty {
      border: 0.1rem solid $c-red;
    }
  }

  &:focus + label,
  &.is-active + label {
    top: 0;
  }
}

.textarea__label {
  position: absolute;
  top: 3rem;
  left: 2rem;
  display: flex;
  align-items: center;
  height: auto;
  padding: 0.5rem;
  pointer-events: none;
  background: $c-beige;
  transform: translateY(-50%);
  transition: top 0.2s;
}

.textarea__error {
  .has-error & {
    display: block;
  }
}
