
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































































$bp: xxl;

.contact-outer {
  perspective: 800px;
  overflow: hidden;
}

.contact,
[class*='contact--'] {
  // prettier-ignore
  @include fluid(padding-top,(xxs: 30px, xxxl: 160px));
  // prettier-ignore
  @include fluid(padding-bottom,(xxs: 30px, xxxl: 160px));

  padding-right: col(2, 20);
  padding-left: col(2, 20);
  color: $c-green;
  background: $c-white;
  opacity: 0;
  transform-origin: 50% 100%;
  // transform: translateY(100px) rotateX(10deg);

  @include mq($bp) {
    display: flex;
    padding-right: 0;
    padding-left: 0;
  }
}

.contact__intro {
  flex-shrink: 0;
  margin-bottom: 2rem;

  @include mq($bp) {
    width: col(10, 20);
    margin: 0 col(1, 20) 0 col(2, 20);
  }
}

.contact__intro__title {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 10px, xxl: 25px));
}

.contact__content {
  @include mq($bp) {
    width: col(5, 20);
    margin-top: 2rem;
  }
}

.contact__content__text {
  margin-bottom: 2rem;
}

.contact__content__link {
  display: inline-block;
  overflow: hidden;
  color: $c-green;
  border-color: $c-green;

  &:hover {
    color: $c-beige;
    border-color: $c-beige;
  }
}
