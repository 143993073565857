
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































.activities__footer {
  // prettier-ignore
  @include fluid(padding-top,(xxs: 45px, xxl: 160px));

  background: $c-skin-light;
}
